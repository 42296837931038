/* .navbar {
  
  background-color: #1C1C1C;
  
  height: 90px;
  position: sticky;
  
 
}

  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    height: 80px;
}

.navbar-brand img {
  width: auto;
  height: 80px;
  padding-left: 4rem;
  padding-top: 5px;
}
.navbar-brand img:hover{
  transition: all 2s;
  transform: translate(5px, 12px);
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  margin-bottom: 1rem;
  font-family: Arial;
  font-weight: normal;
  display: flex;
  align-items: center;
  margin-right: 80px;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: red;
} */

/* @media (max-width: 768px) {
  .navbar-collapse {
    background-color: #333;
   
  }

 

  .navbar-toggler {
    border-color: red;
  }

  .navbar-toggler-icon {
   color: red;
    background-color: black;
  }
}

ul li {
  list-style: none;
  margin-right: 10px;
  border-radius: 20px;
  background: rgb(243, 243, 243);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
 ul li {
  animation: pulse 3s infinite ease-in-out alternate;
}
@keyframes pulse {
  from { transform: scale(0.8); }
  to { transform: scale(1.2); }
} 


ul li::before {
  content: "";
  position: absolute;
  height: 160%;
  width: 140%;
  top: 80%;
  
  background: rgb(0, 96, 223);
  background: linear-gradient(to right, #c92009, #fa251e);
  transform: rotate(45deg);
  transition: all 0.4s ease-in-out;
}

ul li:hover i {
  transition: all 0.2s linear;
  transform: scale(1.2);
  color: white;
}

ul li:hover::before {
  top: -28%;
  left: -7%;
}

ul li i {
  font-size: 16px;
  color: red;
}
.navbar-dark .navbar-nav .nav-link{
   
    animation: pulse 3s infinite ease-in-out alternate;
  
}
@keyframes pulse {
  from { transform: scale(0.8); }
  to { transform: scale(1.2); }
} */

.navbar-top {
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  padding: 25px 35px;
  font-family: "poppins", sans-serif;
  backdrop-filter: blur (8px);
}
.navbar {
  background-color: rgba(28, 28, 28, 0.5);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.15);
}

.navbar2 {
  background-color: #2b2e35;
}

.navbar-nav {
  padding-left: 2rem;
}
.navbar-expand-lg .navbar-nav .nav-link {
  color: white;
}
.navbar-expand-lg .navbar-nav .nav-link:hover {
  background-color: white;
  color: red;
}

.navbar-brand img {
  height: 52px;
  width: auto;
  padding-left: 3rem;
  color: white;
}
.navbar-expand-lg .navbar-collapse {
  padding-right: 5rem;
}

.navbar-light .navbar-toggler-icon {
  background-color: red;
}

.navbar-light .navbar-nav .nav-link:focus {
  color: white;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: red;
  border-radius: 2rem;
}
.nav-pills .nav-link.active {
  background: none;
}
.navbar-light .navbar-nav .nav-link.active {
  color: red;
  background-color: white;
}
