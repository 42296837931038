
.project{
    margin-top: 5rem;
}
.ourteam_name{
    color:#ba3030;
    font-size: 1rem;
    font-weight: 500;
    text-transform:uppercase;
}
.site-heading p{
    font-size: 1.9rem;
}