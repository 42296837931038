.about-text {
  padding: 20px;
}

.about-text h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.site-heading h4 {
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 20px;
  text-align: initial;
}

@media (max-width: 768px) {
  .about-text {
    text-align: center;
  }

  .about-text h2 {
    font-size: 24px;
  }
}
.about__data {
  background-image: url();
}

.main-container {
  min-height: 100vh;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  height: 560px;
  overflow: hidden;
  margin-top: -4rem;
}
.main {
  margin: 0px auto;
  width: 350px;
  height: 350px;
  position: relative;
}
.big-circle {
  height: 100%;
  width: 100%;
  position: relative;
  border: 3px solid red;
  border-radius: 50%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  animation: Rotate 20s linear infinite;
  -webkit-animation: Rotate 20s linear infinite;
}
.icon-block {
  width: 64px;
  height: 64px;
  padding: 8px;
  position: absolute;
  border-radius: 50%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  background-image: linear-gradient(180deg, #df1c1c 0%, #dd0d0d 100%);
  -webkit-background-image:#64080f; 
  box-shadow: 0 2px 4px 0 #64080f;
  -webkit-box-shadow: 0 2px 4px 0 #6d0a0a;
}
.icon-block img {
  margin: 0px auto;
  width: 70%;
  animation: Rotate-reverse 20s linear infinite;
  -webkit-animation: Rotate-reverse 20s linear infinite;
}
.icon-block:first-child {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.icon-block:nth-child(2) {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  -webkit-transform: translate(50%, -50%);
}
.icon-block:nth-child(3) {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  -webkit-transform: translate(-50%, 50%);
}
.icon-block:nth-child(4) {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
/* circle content */
.circle {
  animation: circle-rotate 20s linear infinite;
  -webkit-animation: circle-rotate 20s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  width: 75%;
  height: 75%;
  border: 3px solid #df1c1c;
  border-radius: 50%;
}
.circle .icon-block img {
  animation: img-rotate 20s linear infinite;
  -webkit-animation: img-rotate 20s linear infinite;
}
/* center logo */
.center-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.center-logo img {
  max-width: 200px;
}

/* keyframe animation */

@keyframes Rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes Rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes Rotate-reverse {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes Rotate-reverse {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes circle-rotate {
  from {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(405deg);
  }
}

@-webkit-keyframes circle-rotate {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotate(405deg);
  }
}

@keyframes img-rotate {
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(-405deg);
  }
}

@-webkit-keyframes img-rotate {
  from {
    -webkit-transform: rotate(-45deg);
  }
  to {
    -webkit-transform: rotate(-405deg);
  }
}


.svg-icon-area {
  stroke: red;
  fill: teal;
  color: red;
  filter: green;
}
