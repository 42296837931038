  .type{
    font-family: sans-serif;
    font-weight: 700;
    color: white;
    font-size: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    
    background-image: url('../../assets/gr.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    margin-top: -4px;
}
.type h4{
  color: white;
  font-size: 1.2rem;
}

.Typewriter__cursor{
  color: red;
  font-size: 3rem;
  
}   

 /* .animated-typing {
  background-image: url("../../assets/gr.jpg");
  height: 500px;
  background-size: cover;
  background-position: 100%;
  background-repeat: no-repeat;
  background-color: #dd2476;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animated-typing span {
  font-size: 2.5rem;
  color: white;
  font-weight: 600;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
} 




  */
